<template>
  <div>
    <form action="#" class="form" @submit.prevent.stop="login">
      <div class="mb-3 mt-5">
        <input v-model="form.email" :disabled="loading" aria-label="Email Input"
               class="form-control es-bg-secondary border-0 p-3 text-center" placeholder="Email" type="email">
        <p v-if="errors.email" class="text-danger mt-1" v-text="errors.email"/>
      </div>
      <div class="mb-3">
        <input v-model="form.password" :disabled="loading" aria-label="Password Input"
               class="form-control es-bg-secondary border-0 p-3 text-center" placeholder="Password" type="password">
        <p v-if="errors.password" class="text-danger mt-1" v-text="errors.password"/>
      </div>

      <div class="form-check mb-3">
        <input id="rememberMeCheckbox" v-model="form.remember" class="form-check-input" type="checkbox" value="">
        <label class="form-check-label" for="rememberMeCheckbox">Remember me.</label>
      </div>

      <button :disabled="loading" class="w-100 btn btn-primary btn-primary-es rounded-0 p-3 position-relative"
              type="submit">
        <Spinner :loading="loading" color="white"/>
        <span v-show="!loading">Login</span>
      </button>
    </form>

    <div class="d-flex flex-column gap-2 mt-4">
      <div class="text-center">Don’t have an account yet?
        <RouterLink :to="{name: 'Register'}" v-text="'Join Now'"/>
      </div>
      <div class="text-center">
        <RouterLink :to="{name: 'ForgotPassword'}" v-text="'Having trouble signing in?'"/>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Spinner from '@core/components/loading/Spinner.vue';
import notify from '@core/utils/notify.js';

export default {
  name: 'Login',
  components: {Spinner},
  data() {
    return {
      form: {
        email: '',
        password: '',
        remember: false,
      },
      loading: false,
      errors: {
        email: '',
        password: '',
      },
    };
  },
  methods: {
    async login() {
      if (this.loading) {
        return;
      }

      this.resetErrors();

      this.loading = true;

      try {
        const {data: {message, redirect}} = await axios.request({
          method: 'POST',
          data: this.form,
          url: '/login',
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
          },
        });

        message && notify.success(message);

        if (redirect) {
          window.location = redirect;
        }
      } catch (e) {
        console.dir(e);
        if (!e.response) {
          notify.error('Something went wrong!');
          return;
        }

        const {errors, message, redirect} = e.response.data ?? {};

        message && notify.error(message);

        if (redirect) {
          window.location = redirect;
          return;
        }

        this.errors.email = Array.isArray(errors?.email) ? errors?.email[0] : '';
        this.errors.password = Array.isArray(errors?.password) ? errors?.password[0] : '';

      } finally {
        this.loading = false;
      }
    },
    resetErrors() {
      this.errors.email = '';
      this.errors.password = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.es-bg-secondary {
  background-color: #f1f1f1;
}

.btn-primary-es {
  background: #3152a3;
  border-color: #3152a3;
}
</style>
