import {createStore} from 'vuex';

const store = createStore({
  state() {
    return {
      login: {},
      register: {},
    };
  },
});

export default store;
